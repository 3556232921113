import React from "react"
import { useEffect, useState } from "react"
import { Block } from "baseui/block"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "./Calendar.css"

const localizer = momentLocalizer(moment)

const CalendarComponent = ({ events }) => {
  const getWindowDimensions = () => {
    const windowGlobal = typeof window !== "undefined" && window
    const { innerWidth: width, innerHeight: height } = windowGlobal
    return {
      width,
      height,
    }
  }

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    )

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
  }

  const { width } = useWindowDimensions()

  return (
    <Block padding={["15px", "15px", "25px", "25px"]}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: width < 450 ? "400px" : "550px" }}
        formats={{ agendaDateFormat: "dddd DD MMM YYYY" }}
        messages={{ noEventsInRange: "ไม่มีตารางงานในช่วงเวลานี้" }}
      />
    </Block>
  )
}

export default CalendarComponent
