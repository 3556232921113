import React, { useEffect } from 'react'
import { Block } from 'baseui/block'
import { Tag } from 'baseui/tag'
import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid'
import { Button } from 'baseui/button'
import CloudApi from 'cloudApi/api'
import { Paragraph2, Label1 } from 'baseui/typography'
import { Header } from 'components/molecules'
import Calendar from 'components/organisms/Calendar'
import { Pagination } from 'baseui/pagination'
import withJob from 'hoc/withJob'
import withUser from 'hoc/withUser'
import checkPermission from 'hoc/checkPermission'
import moment from 'moment'
import StudentProfile from '../components/molecules/StudentProfile'
import { format } from 'date-fns'

const Profile = ({ jobState, jobDispatch, userState }) => {
  useEffect(() => {
    CloudApi.getJobsApplied().then(appliedJob =>
      jobDispatch({ type: 'FETCH_APPLIED_JOBS_DATA', payload: appliedJob })
    )
  }, [])

  const [ page, setPage ] = React.useState(1)
  const [ pageState, setPageState ] = React.useState('profile')
  const limit = 12
  const { appliedJobs } = jobState

  const handlePageChange = nextPage => {
    if (nextPage < 1) {
      return
    }
    if (nextPage > Math.ceil(appliedJobs.length || 100 / limit)) {
      return
    }
    setPage(nextPage)
  }

  const window = () => {
    const min = (page - 1) * limit
    return appliedJobs.slice(min, min + limit)
  }

  const numPages = appliedJobs.length
    ? Math.ceil(appliedJobs.length / limit)
    : 1

  const setJobCalendar = () => {
    const event = []
    if (appliedJobs.length > 0) {
      appliedJobs
        .filter(appliedJob => appliedJob.status === 'confirm')
        .map(appliedJob => {
          return appliedJob.dates.map(date => {
            event.push({
              title: appliedJob.title,
              start: new Date(
                `${moment(date).format('YYYY-MM-DD')} ${moment(
                  appliedJob.start_time
                ).format('HH:mm:ss')}`
              ),
              end: new Date(
                `${moment(date).format('YYYY-MM-DD')} ${moment(
                  appliedJob.end_time
                ).format('HH:mm:ss')}`
              ),
            })
          })
        })
      return event
    }
  }

  const mapJobStatusWithTag = status => {
    let jobStatus = {
      color: '#08a35a',
      status: 'ผ่านเกณฑ์'
    }
    if (status === 'pending') {
      jobStatus = {
        color: '#FFB809',
        status: 'รอการยืนยัน'
      }
    } else if (status === 'reject') {
      jobStatus = {
        color: '#E54936',
        status: 'ไม่ผ่านเกณฑ์'
      }
    }
    return <Tag
      variant="outlined"
      closeable={false}
      overrides={{
        Root: {
          style: {
            borderColor: jobStatus.color,
          },
        },
        Text: {
          style: {
            color: jobStatus.color,
          },
        },
      }}
    >
      {jobStatus.status}
    </Tag>
  }

  return (
    <Block>
      <Header/>
      {pageState === 'profile' && (
        <Block>
          <Block
            padding={[ '20px', '20px', '40px', '40px' ]}
            display={[ 'column', 'column', 'flex', 'flex' ]}
            justifyContent={'space-around'}
            alignItems={'flex-start'}
          >
            <StudentProfile column student={userState.user}/>
            <Block flex={2}>
              <Block
                display={'flex'}
                justifyContent={'flex-end'}
                paddingBottom={'20px'}
              >
                {appliedJobs.length > 0 &&
                appliedJobs.filter(
                  appliedJob => appliedJob.status === 'confirm'
                ).length > 0 && (
                  <Button onClick={() => setPageState('calendar')}>
                    ดูปฎิทินงานของฉัน
                  </Button>
                )}
              </Block>
              <StyledTable $gridTemplateColumns="auto minmax(auto, 500px) auto">
                <StyledHeadCell>
                  <Paragraph2
                    marginBottom={'0px'}
                    marginLeft={'auto'}
                    marginRight={'auto'}
                  >
                    วันที่สมัคร
                  </Paragraph2>
                </StyledHeadCell>
                <StyledHeadCell>
                  <Paragraph2
                    marginBottom={'0px'}
                    marginLeft={'auto'}
                    marginRight={'auto'}
                  >
                    ชื่องาน
                  </Paragraph2>
                </StyledHeadCell>
                <StyledHeadCell>
                  <Paragraph2
                    marginBottom={'0px'}
                    marginLeft={'auto'}
                    marginRight={'auto'}
                  >
                    สถานะ
                  </Paragraph2>
                </StyledHeadCell>
                {appliedJobs &&
                appliedJobs.length > 0 &&
                window().map(job => (
                  <React.Fragment>
                    <StyledBodyCell>
                      <Block>
                        <Paragraph2>
                          {format(
                            new Date(job.apply_created_at),
                            'dd MMM yyyy'
                          )}
                        </Paragraph2>
                      </Block>
                    </StyledBodyCell>
                    <StyledBodyCell>
                      <Block minWidth={'200px'}>
                        <Paragraph2 marginBottom={'0px'}>
                          {job.title}
                        </Paragraph2>
                      </Block>
                    </StyledBodyCell>
                    <StyledBodyCell>
                      <Block display={'flex'} alignItems={'center'} height={'100%'}>
                        {mapJobStatusWithTag(job.status)}
                      </Block>
                    </StyledBodyCell>
                  </React.Fragment>
                ))}
              </StyledTable>
              <Pagination
                currentPage={page}
                numPages={numPages}
                onPageChange={({ nextPage }) => handlePageChange(nextPage)}
                overrides={{
                  Root: {
                    style: { justifyContent: 'flex-end', marginTop: '25px' },
                  },
                }}
              />
            </Block>
          </Block>
        </Block>
      )}
      {pageState === 'calendar' && (
        <Block
          height={[ '350px', '350px', '500px', '500px' ]}
          padding={[ '20px', '20px', '30px', '30px' ]}
        >
          <Label1
            style={{
              color: '#fc7c2e',
              textDecoration: 'underline',
              marginTop: 20,
              marginLeft: 20,
              cursor: 'pointer',
            }}
            onClick={() => setPageState('profile')}
          >
            {'< กลับไป profile'}
          </Label1>
          <Calendar events={setJobCalendar()}/>
        </Block>
      )}
    </Block>
  )
}

export default withUser(withJob(checkPermission([ 'student' ], Profile)))
